<template>
  <v-card width="313" height="300" class="secondaryDark d-flex flex-column card-shadow">
    <v-card class="secondaryDark pa-0 ma-0 scroll" height="280" flat tile>
      <label class="font-weight-medium pa-3 caption textDark--text" v-if="text">{{ text }}</label>

      <v-card
        v-for="(chat, index) in chats"
        :key="index"
        flat
        active-class
        class="secondaryDark ma-0 pa-0 d-flex flex-column"
      >
        <v-row no-gutters class="d-flex flex-row align-center" v-if="chat.vector == 'incoming'">
          <v-col v-if="trainnee" lg="2" sm="2" cols="2" class="ml-4 mt-4 d-flex align-self-center">
            <v-img max-width="51" max-height="50" :src="chat.image"></v-img>
          </v-col>
          <v-col class="mt-3 ml-3 mr-3 ma-0">
            <label v-if="role='trainnee'" class="caption primary--text">
              {{
              chat.name
              }}
            </label>
            <label v-if="role='evaluator'" class="caption textDark--text">
              {{
              chat.date
              }}
            </label>

            <v-card
              width="122"
              class="textLight--text primary pa-1 body-2 font-weight-light"
            >{{ chat.text }}</v-card>
          </v-col>
        </v-row>
        <v-row no-gutters justify="end">
          <v-col lg="5" sm="5" cols="5" class="mt-3 ml-3 mr-3 ma-0">
            <v-card
              width="122"
              v-if="chat.vector == 'outgoing'"
              class="textLight--text textDark pa-1 font-weight-light body-2"
            >{{ chat.text }}</v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
    <v-card width="313" class="ma-0 px-3 d-flex flex-row primary mt-2 text-container" flat tile>
      <v-text-field
        @keyup.enter="addChat()"
        v-model="chat"
        dense
        height="32"
        background-color="secondaryDark"
        :placeholder="placeholder"
        type="text"
        class="pa-0 ma-0 mt-2 rounded"
      ></v-text-field>
      <bz-button class="ma-0 pa-0 mt-2 round" height="32" tile @click="addChat()" title="Enter"></bz-button>
    </v-card>
  </v-card>
</template>
<style scoped>
.scroll {
  overflow-y: auto;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.rounded {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 6px;
}
.round {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.text-container {
  height: 45px;
  width: 313px;
}
</style>
<script>
import BzButton from "@/shared/components/BzSubmitButton.vue";
export default {
  name: "BzChat",
  components: {
    BzButton
  },
  data() {
    return {
      chat: ""
    };
  },
  props: ["chats", "text", "placeholder", "role"],

  methods: {
    addChat() {
      if (this.chat !== "") {
        this.chats.push({ vector: "outgoing", text: this.chat });
        this.chat = "";
      }
    }
  }
};
</script>

<style></style>
