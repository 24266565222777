<template>
  <v-container fill-height fluid class="ma-0 pa-0">
    <admin-header :title="headerTitle" :links="bcLinks"></admin-header>
    <v-btn :to="from" small text class="ma-0 pa-0">
      <v-icon small color="primary" class="mr-2">mdi-arrow-left</v-icon>
      <auth-link-button small title="Back to Trainees"></auth-link-button>
    </v-btn>
    <v-row class="mr-12 mb-12" no-gutters>
      <v-col class="mt-1" cols="7">
        <!-- <v-skeleton-loader v-if="loading" class="mt-2" type="list-item-avatar-two-line"></v-skeleton-loader> -->
        <v-card flat class="d-flex card-shadow align-center mt-2 mb-4">
          <v-avatar size="95" color="textOrange" tile>
            <v-img v-if="trainees.image" :src="trainees.image"></v-img>
            <span v-else class="white--text">
              {{
              trainees.name.charAt(0)
              }}
            </span>
          </v-avatar>
          <div class="ml-6 pa-2 d-flex flex-column">
            <label class="body-2 font-weight-light textDark--text">{{ trainees.name }}
              {{ trainees.evaluated }}
            </label>
            <label class="font-weight-light secondaryText--text caption">{{ trainees.email }}</label>
            <label
              v-if="trainees.evaluated"
              class="font-weight-light primary--text body-2 mt-4 caption"
            >Evaluated</label>
            <label
              v-else
              class="font-weight-light textOrange--text body-2 mt-4 caption"
            >Not Evaluated</label>
          </div>
        </v-card>
        <v-row no-gutters class="d-flex flex-column">
          <label class="textOrange--text body-2 mt-3 mb-n3">Submission</label>
          <v-card
            flat
            width="100%"
            min-height="50vh"
            max-height="50vh"
            class="mt-4 pt-2 card-shadow"
            style="overflow-y: auto;"
          >
            <v-card-text
              v-for="(step, index) in steps"
              :key="index"
              class="pa-2 ma-0"
              @click="navigateTo(index, step.fileType, step.id)"
            >
              <v-card class="d-flex justify-start flex-row  pa-2" elevation="5" flat  hover>
                <v-icon large color="primary">
                  {{
                  step.fileType | stepIcon
                  }}
                </v-icon>
                <v-card class="d-flex flex-column ml-4" flat tile>
                  <div
                    v-if="step.isOptional"
                    class="textOrange--text font-weight-light caption"
                  >[ Optional ]</div>
                  <v-card-title class="primary--text body-2 ma-0 pa-0">
                    {{
                    step.title
                    }}
                  </v-card-title>
                  <v-card-text
                    class="textDark--text font-weight-light caption ma-0 pa-0"
                  >{{ step.description }}</v-card-text>
                </v-card>
              </v-card>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
      <!-- <v-col class="mr-12">
        <label class="textOrange--text font-weight-bold ml-7 caption">CHAT HERE</label>
        <bz-chat class="ml-6 mt-2" placeholder="Message" role="trainnee" :chats="chats"></bz-chat>
      </v-col>-->
    </v-row>
    <v-footer height="65" absolute class="d-flex justify-center">
      <div class="d-flex flex-row">
        <p
          class="body-1 textDark--text mr-12 my-2"
        >Click on Evaluate Submission to rate the selected user</p>
        <BzButton title="Evaluate Submission" depressed width="240" @click="navigateToEvaluate()"></BzButton>
      </div>
    </v-footer>
    <!-- <bz-footer
      text=""
      buttonTitle=""
      @click="navigateToEvaluate()"
      :to=""
    ></bz-footer>-->
  </v-container>
</template>

<script>
import AuthLinkButton from "@/auth/components/AuthLinkButton";
import BzChat from "@/beez-modules/beez-evaluator/components/BzChat.vue";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import AdminHeader from "@/beez-admin/components/AdminHeader";
import isEmpty from "lodash.isempty";
import BzButton from "@/shared/components/BzSubmitButton";

export default {
  name: "TraineeInfo",
  components: {
    BzChat,
    AuthLinkButton,
    AdminHeader,
    BzButton
  },
  beforeMount() {
    if (isEmpty(this.$route.params)) {
      this.$router.go(-1);
    }
    this.trainees.name = this.$route.params.name;
    this.trainees.image = this.$route.params.image;
    this.trainees.email = this.$route.params.email;
    this.trainees.evaluated =  this.$route.params.evaluated;
    this.courseId = this.$route.params.courseId;
    this.traineeSubmission = this.$route.params.submissions;
    this.submissionId = this.$route.params.submissionId;
    this.storeTraineeSubmission(this.traineeSubmission);
    if (this.evaluatorTrainings.length > 0) {
      this.getCourseTrainings();
    }
    // this.refresh();
  },
  data() {
    return {
      from: null,
      courseId: "",
      courseDetail: null,
      steps: [],
      fileTypes: [],
      submissionId: null,
      trainees: {
        name: "",
        email: "",
        evaluated: Boolean,
        image: ""
      },
      traineeSubmission: null,
      headerTitle: "Course Name",
      chats: [
        {
          vector: "incoming",
          text: "Hello Haile",
          image: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
          name: "Kebede Alemu"
        },
        { vector: "outgoing", text: "I think He is a great Candidate." },
        {
          vector: "incoming",
          text: "Hello Haile",
          image: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
          name: "Haile Alemu"
        },
        { vector: "outgoing", text: "I think He is a great Candidate." }
      ]
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // access to component instance via `vm`
      vm.from = from;
    });
  },
  
  computed: {
     
    ...mapFields("auth", ["currentUser"]),
    ...mapFields("evaluator", ["evaluatorTrainings"]),
    bcLinks() {
      return [
        {
          text: "Elevation Module",
          disabled: true
        },
        {
          text: "Trainees",
          disabled: false,
          to: this.from
        },
        {
          text: "Trainee Details",
          disabled: true
        }
      ];
    }
  },

  filters: {
    stepIcon(fileType) {
      switch (fileType) {
        case "Text":
          return "mdi-format-size";
        case "Video":
          return "mdi-play-box-multiple";
        case "Image":
          return "mdi-image";
        default:
          return "mdi-text-box";
      }
    }
  },
  watch: {
    evaluatorTrainings: {
      handler() {
        this.getCourseTrainings();
      }
    }
  },
  methods: {
    ...mapActions("userElevation", {
      getCourse: "getCourse",
      getEvaluateeCourse: "getEvaluateeCourse"
    }),
    ...mapActions("evaluator", {
      getEvaluatorTraining: "getEvaluatorTraining",
      storeTraineeSubmission: "storeTraineeSubmission",
      storeFileTypes: "storeFileTypes"
    }),
    navigateToEvaluate() {
      this.$router.push({
        name: "evaluate-trainee",
        query: {
          i: this.submissionId
        }
      });
    },
    async getCourseTrainings() {
      this.evaluatorTrainings.forEach(element => {
        const { courses } = element;
        if (courses) {
          this.courseDetail = courses.find(e => e._id === this.courseId);
        }
      
      });

      const { submissionSteps } = this.courseDetail;

      if (submissionSteps.length > 0) {
        submissionSteps.map(element => {
          this.steps.push({
            fileType: element.fileType,
            description: element.description,
            title: element.name,
            isOptional: element.isOptional,
            id: element._id
          });
          this.fileTypes.push({ fileType: element.fileType });
        });
        this.storeFileTypes(this.fileTypes);
      }
    },
    navigateTo(index, fileType, Id) {
      this.fileId = this.steps[index].id;
      this.$router.push({
        name: "SubmissionList",
        query: {
          id: this.steps[index].id,
          userId: this.$route.query.userId,
          fileType: fileType
        }
      });
    }
  }
};
</script>

<style></style>
